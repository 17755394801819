import React, { useContext, useState, useEffect } from 'react';
import { DataContext } from '../../db/dataProvider';
import Nombre from './nombre';
import RFC from './rfc';
import DomicilioFiscal from './DomicilioFiscal';
import RegimenFiscalDropdown, { regimenOptions } from './RegimenFiscal';
import EmailInput from './email';
import Telefono from './telefono';
import { useNavigate, useLocation } from 'react-router-dom';
import PdfCF from './PdfCF';
import './factura.css';  // Assuming your CSS is saved in styles.css

function FacturaPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { ticket, setTicket } = useContext(DataContext);
  const { receptor, setReceptor } = useContext(DataContext);
  const { branch } = useContext(DataContext); // Access branch data from context

  const initialReceptor = receptor || {
    nombreReceptor: { value: '', isValid: false },
    rfc: { value: '', isValid: false },
    domicilioFiscal: { value: '', isValid: false },
    email: { value: '', isValid: false },
    regimenFiscal: null
  };

  const [nombreReceptor, setNombreReceptor] = useState(initialReceptor.nombreReceptor);
  const [rfc, setRfc] = useState(initialReceptor.rfc);
  const [domicilioFiscal, setDomicilioFiscal] = useState(initialReceptor.domicilioFiscal);
  const [email, setEmail] = useState(initialReceptor.email);
  const [regimenFiscal, setRegimenFiscal] = useState(initialReceptor.regimenFiscal);
  const [facturaProcessed, setFacturaProcessed] = useState(false); // Track if factura has been processed
  const [extractionFailed, setExtractionFailed] = useState(false); // Track extraction failure

  useEffect(() => {
    if (!ticket) {
      navigate('/');
    } else if (location.state && location.state.receptorData) {
      const { nombreReceptor, rfc, domicilioFiscal, email, regimenFiscal } = location.state.receptorData;
      setNombreReceptor(nombreReceptor);
      setRfc(rfc);
      setDomicilioFiscal(domicilioFiscal);
      setEmail(email);
      setRegimenFiscal(regimenFiscal);
      setFacturaProcessed(false); // Allow re-processing
    } else if (ticket.factura && !facturaProcessed) {
      // If factura already exists, set all fields to empty
      setNombreReceptor({ value: '', isValid: true });
      setRfc({ value: '', isValid: true });
      setDomicilioFiscal({ value: '', isValid: true });
      setEmail({ value: '', isValid: true });
      setRegimenFiscal(null);
      handleFacturar();
    }
  }, [location.state, ticket, facturaProcessed, navigate]);

  const allFieldsValid = () => {
    if (ticket && ticket.factura) {
      return true; // If factura exists, bypass validation
    }
    return nombreReceptor.isValid && rfc.isValid && domicilioFiscal.isValid;
  };

  const handleFacturar = () => {
    if (allFieldsValid()) {
      const receptorData = ticket && ticket.factura
        ? { nombreReceptor: { value: '' }, rfc: { value: '' }, domicilioFiscal: { value: '' }, email: { value: '' }, regimenFiscal: null }
        : { nombreReceptor, rfc, domicilioFiscal, email, regimenFiscal };

      setReceptor(receptorData);
      setFacturaProcessed(true); // Set facturaProcessed to true to prevent repeated execution
      navigate('/resultado', { state: { receptorData, previousPage: 'FacturaPage' } });
    }
  };

  const handleCancelar = () => {
    setNombreReceptor({ value: '', isValid: false });
    setRfc({ value: '', isValid: false });
    setDomicilioFiscal({ value: '', isValid: false });
    setEmail({ value: '', isValid: false });
    setRegimenFiscal(null);
  };

  const handleExtractedData = (data) => {
    const nombre = data["Denominación/Razón Social"];
    const rfc = data.RFC;
    const codigoPostal = data["Código Postal"];
    const regimen = data.Regímenes.length > 0 ? regimenOptions.find(option => option.value === data.Regímenes[0].numero) : null;

    if (nombre && rfc && codigoPostal) {
      setNombreReceptor({ value: nombre, isValid: true });
      setRfc({ value: rfc, isValid: true });
      setDomicilioFiscal({ value: codigoPostal, isValid: true });
      setRegimenFiscal(regimen || null); // Set to the matched regimen or null
      setExtractionFailed(false);
    } else {
      setExtractionFailed(true);
    }
  };

  return (
    <div>
      <h1>Captura de datos Fiscales</h1>
      {ticket && ticket.ventas && ticket.ventas.length > 0 ? (
        <div className="ticket-container">
          {ticket.ventas.map((venta, index) => (
            <div key={index} className="ticket">
              <p><strong>ID del ticket:</strong> {venta.uid_ventas}</p>
              <p><strong>Fecha:</strong> {venta.fecha}</p>
              <p><strong>Corte ID:</strong> {venta.corte_id}</p>
              <p><strong>Total:</strong> {venta.total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</p>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <p>No ticket data available.</p>
        </div>
      )}
      <div className="pdf-extraction-container">
        <h3>Si tiene la Cedula de identicación fiscal puede intentar Extraer <span className="optional">(Opcional)</span></h3>
        <PdfCF onExtractedData={handleExtractedData} />
        {extractionFailed && <p className="error-message">Este PDF no pudo ser procesado.</p>}
      </div>

      <div className="form-container">
        <Nombre nr={nombreReceptor} setNR={setNombreReceptor} />
        <RFC rfc={rfc} setRFC={setRfc} />
        <DomicilioFiscal df={domicilioFiscal} setDF={setDomicilioFiscal} />
        <RegimenFiscalDropdown rf={regimenFiscal} setRF={setRegimenFiscal} />
        <EmailInput emailIn={email} setEm={setEmail} />
      </div>

      <div className="button-group">
        <button type="button" onClick={handleFacturar} disabled={!allFieldsValid()} className="btn btn-primary">
          Facturar
        </button>
        <button type="button" onClick={handleCancelar} className="btn btn-secondary">
          Cancelar
        </button>
      </div>
    </div>
  );
}

export default FacturaPage;
